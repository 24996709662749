<template>
	<div>
		<h4>Choose your plan</h4>
		<div class="input-group mb-3" v-if="is_on_new_plan">
			<div class="input-group-prepend" style='margin-right: -5px;'>
				<label class="input-group-text" style='border-top-left-radius: 3px; border-bottom-left-radius: 3px' for="billing_cycle">Billing Cycle</label>
			</div>

			<select class="form-control" id="billing_cycle"
				@change="e => $emit('change-billing-cycle', e.target.value)"
				:disabled="site.subscription_status === 'Paid' && site.subscription_type === 'Yearly'"
			>
				<option
					v-for="o in billing_cycle_options"
					:value="o.value"
					:selected="cart.billing_cycle == o.value"
					:key="o.value"
				>
					{{ o.label }}
				</option>
			</select>
		</div>
		<div class="row">
			<div class="col-12 col-md mb-3 mb-md-0" v-for="plan in plans" :key="plan.name">
				<PlanCard
					@click.native="$emit('change-plan', plan.name)"
					:plan="plan"
					:is_active="plan.name === active_plan"
					:currency="currency"
					:billing_cycle="cart.billing_cycle"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import PlanCard from './PlanCard.vue'

export default {
	name: 'Plans',
	props: ['site', 'plans', 'cart', 'legacy_plan'],
	components: {
		PlanCard
	},
	computed: {
		is_on_new_plan() {
			return this.plans
				.map(d => d.name)
				.includes(this.site.base_plan);
		},
		active_plan() {
			return this.cart.plan;
		},
		currency() {
			if (this.site.currency){
				return this.site.currency;
			}
			else{
				return this.site.country === 'India' ? 'INR' : 'USD';
			}
		},
		currency_symbol() {
			return this.currency === 'INR' ? '₹' : '$';
		},
		billing_cycle_options() {
			let options = [
				{ label: this.site.apply_ngo_discount? 'Annual (30% discount)' : 'Annual', value: 'Yearly' }
			];

			if (this.site.base_plan === 'Cloud-2020' && this.site.subscription_status != 'Paid'){
				this.cart.billing_cycle = 'Yearly';
			}
			else {
				options.push({ label: 'Monthly', value: 'Monthly' });
			}

			return options;
		}

	}
}
</script>

<style>
.plan-card {
	cursor: pointer;
	border-width: 2px !important;
}
</style>
