<template>
	<div>
		<div v-if="cart_details.cart && cart_details.cart.length && pending_downgrades.length == 0">
			<h5>Checkout</h5>
			<div>
				<div class="row border-bottom text-muted">
					<div class="col-5 py-2 px-3">
						Item
					</div>
					<div class="col-2 py-2 px-3 text-right text-nowrap">
						Qty
					</div>
					<div class="col-4 py-2 px-3  text-nowrap">
						<span v-if="!is_downgrade || auto_renewal" style='float:right;'> Amount </span>
						<span v-else style='float:left;'>Details</span>
					</div>
					<div class="col-1"></div>
				</div>
				<div class="row" v-for="item in cart_details.cart" :key="item.upgrade_type">
					<div class="col-5 py-2 px-3">
						{{ item.upgrade_type }}
						<span v-if="is_subscription(item) && item.has_addons">
							<ul>
								<li> Add-Ons</li>
								<ul>
									<li v-if="item.extra_users>0">{{ item.extra_users }} Users @ {{ item.extra_users_rate }}</li>
									<li v-if="item.extra_space>0">{{ item.extra_space }} GB Space @ {{ item.extra_space_rate }}</li>
									<li v-if="item.extra_emails>0">{{ item.extra_emails }} Emails @ {{ item.extra_emails_rate }}</li>
								</ul>
							</ul>
						</span>
					</div>
					<div class="col-2 py-2 px-3 text-right text-nowrap">
						{{ item.value }}
					</div>
					<div class="col-4 py-2 px-3 text-right text-nowrap" v-if="!is_downgrade || auto_renewal">
						{{ item.amt_to_display }}
					</div>
					<div class="col py-2 px-3 text-left" v-if="is_downgrade && !auto_renewal">
						{{item.description}} <br> {{ item.expiry }}
					</div>
					<div class="col-1 d-flex justify-content-center align-items-center" @click.prevent="$emit('remove_from_cart', item.upgrade_type)">
						<a class="text-muted" v-if="!is_subscription(item)" href @click.prevent>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 28 28" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
						</a>
						<span v-else></span>
					</div>
				</div>
				<div class="row font-weight-bold border-top">
					<div class="col-5 py-2 px-3">
						Total
					</div>
					<div class="col-2 py-2 px-3 text-right text-nowrap"></div>
					<div class="col-4 py-2 px-3 text-right text-nowrap" v-if="!is_downgrade || auto_renewal">
						{{ cart_details.total_to_display }}
					</div>
					<div class="col-1"></div>
				</div>
			</div>
			<div class='mt-3 row'>
				<div class='col-6' @click.prevent="$emit('address')">
					<span>
						<a href="" class="text-primary text-muted" v-if="address.address">Change Billing Address</a>
						<a href="" class="text-primary text-muted" v-else>Set Billing Address</a>
					</span>
				</div>

				<div class='col-5 text-nowrap' @click.prevent="$emit('switch_billing_cycle')" v-if="!legacy_plan && site.subscription_status=='Trial' && !is_downgrade">
					<span class="float-right">
						<a href="" class="text-primary" style='color: green !important;' v-if="cart.billing_cycle=='Monthly'"> {{ setup_discount_offering() }}</a>
					</span>
				</div>
				<div class="col-1"></div>
			</div>
			<div class="mt-3" @click="$emit('pay')" v-if="show_pay_button">
				<button v-if="site.country === 'India'" class="btn btn-primary">Pay via Razorpay</button>
				<button v-else-if="site.country === 'Indonesia'" class="btn btn-primary">Pay via Midtrans</button>
				<button v-else class="btn btn-primary">Pay via PayPal</button>
			</div>
			<div class="mt-3" @click="$emit('process_downgrade')" v-if="is_downgrade">
				<button  class="btn btn-primary"> Process Downgrade </button>
			</div>
			<div class="mt-3" @click="$emit('pay')" v-if="show_pay_via_credit">
				<button  class="btn btn-primary"> Pay via Credits </button>
			</div>
			<div class="mt-3" v-if="show_buy_credits">
				<a class="btn btn-secondary" v-bind:href="prm_url" target="_blank"> Buy Credits </a>
			</div>
		</div>
		<div class="alert alert-warning mt-3" v-if="auto_renewal && !plan_or_cycle_changed && !is_addon_changed">
				You card will be charged automatically every month
				<CancelSubscription
				:cart_details="cart_details"
				:site="site"
				:email="email" />
		</div>
		<div class='alert alert-warning mt-3' style='position:relative;' v-if="pending_downgrades.length > 0">
			<div v-for="item in pending_downgrades" :key="item.type" >
				Downgrade is scheduled on {{ item.scheduled_on }} for {{ item.type }}: {{ item.value }}
			</div>
			<div class='d-flex' style="position: absolute; right: 1rem; top: 1rem; bottom: 1rem;" @click.prevent="$emit('cancel_downgrade')">
				<a href="" class='text-muted small'> Cancel Downgrade </a>
			</div>
		</div>
	</div>
</template>
<script>
import CancelSubscription from './CancelSubscription.vue';
export default {
	name: 'Cart',
	props: ['cart', 'site', 'cart_details', 'address', 'pending_downgrades', 'erpnext_partner', 'legacy_plan', 'plan_wise_discount', 'email'],
	components: {
		CancelSubscription
	},
	methods: {
		is_subscription(item) {
			return ['Subscription', 'Plan'].includes(item.upgrade_type);
		},
		setup_discount_offering() {
			if(this.cart.plan in this.plan_wise_discount){
				return 'Get '+ this.plan_wise_discount[this.cart.plan] +' % discount on yearly subscription';
			}
			else{
				return 'Switch to yearly subscription';
			}
		},
	},
	computed: {
		is_billing_cycle_changed() {
			return this.site.subscription_type !== this.cart.billing_cycle;
		},
		is_plan_changed() {
			return this.site.base_plan !== this.cart.plan;
		},
		is_addon_changed(){
			return Boolean(this.cart.users || this.cart.emails || this.cart.space);
		},
		plan_or_cycle_changed() {
			return this.is_billing_cycle_changed || this.is_plan_changed;
		},
		show_pay_button() {
			return  !this.erpnext_partner && !this.is_downgrade &&  ((this.plan_or_cycle_changed && this.address.address) || !this.auto_renewal || this.is_addon_changed);
		},
		auto_renewal() {
			return this.site.subscription_status === 'Paid' && (this.site.subscription_type === 'Monthly' && Boolean(this.site.subscription_id)) && !this.is_billing_cycle_changed;
		},
		is_downgrade() {
			return this.cart_details.is_downgrade;
		},
		show_pay_via_credit() {
			return this.erpnext_partner && this.cart_details.can_allocate_credit;
		},
		show_buy_credits() {
			return this.erpnext_partner && !this.cart_details.can_allocate_credit;
		},
		prm_url() {
			return this.cart_details.prm_url;
		}
	},
}
</script>
