<template>
	<div
		class="card plan-card"
		:class="{'border border-primary': is_active}"
	>
		<div
			v-if="is_active"
			class="bg-primary rounded-circle text-white d-flex justify-content-center align-items-center"
			style="width: 1.5em; position: absolute; height: 1.5em; right: 1rem; top: 1rem"
		>
			<Check />
		</div>
		<div class="card-body">
			<h6 class="card-title text-uppercase ">{{ plan.title }}
				<span v-if="billing_cycle === 'Yearly' && plan.apply_discount" class='ml-2' style="font-size: 1em; color: green">
					{{ plan.discount }}% Off
				</span>
			</h6>
			<div class="row">
				<div class="col-6 col-md-12 text-dark text-nowrap">
					<span v-if="billing_cycle === 'Yearly'">
						<span style="font-size: 1.5em;">{{ currency_symbol }}</span>
						<span class="display-4" style="font-size: 2.5rem;">{{ get_price(plan) }}</span>
						<span class="text-muted">/year</span>
						<br>
						<span class="display-4 ml-4 text-muted" style="font-size: 1.6rem;">
							<s v-if="plan.apply_discount">{{ get_non_dicounted_price(plan) }}</s></span>
					</span>
					<span v-else>
						<span style="font-size: 1.5em;">{{ currency_symbol }}</span>
						<span class="display-4" style="font-size: 2.5rem;">{{ get_price(plan) }}</span>
						<span class="text-muted">/month</span>
					</span>
				</div>
				<div class="col-6 col-md-12 small mt-md-2">
					<div class="d-flex align-items-center">
						<Check class="mr-2 mt-1" />
						{{ plan.users }} users
					</div>
					<div class="d-flex align-items-center">
						<Check class="mr-2 mt-1" />
						{{ plan.space }}GB of storage
					</div>
					<div class="d-flex align-items-center">
						<Check class="mr-2 mt-1" />
						{{ plan.emails }} emails per month
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Check from './Check.vue';

export default {
	name: 'PlanCard',
	props: ['plan', 'is_active', 'currency', 'billing_cycle'],
	components: {
		Check
	},
	methods: {
		get_price(plan) {
			let amount_row = plan.amounts.find(d => d.currency === this.currency);
			let price = this.billing_cycle === 'Yearly' ? amount_row.amount : amount_row.monthly_amount;
			return format_number(price, '#,###');
		},
		get_non_dicounted_price(plan) {
			let amount_row = plan.amounts.find(d => d.currency === this.currency);
			let price = this.billing_cycle === 'Yearly' ? amount_row.non_discounted_amount : amount_row.monthly_amount;
			return format_number(price, '#,###');
		}

	},
	computed: {
		currency_symbol() {
			return this.currency === 'INR' ? '₹' : '$';
		}
	}
}
</script>
