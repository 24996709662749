<template>
	<div>
		<h5>Add-Ons</h5>
		<div class="row">
			<div class="col-12 col-md mb-3 mb-md-0" v-for="item in items" :key="item.addon_type">
				<div class="card h-100">
					<div class="card-body">
						<h6 class="card-title text-uppercase ">{{ item.addon_type }}</h6>
						<div>
							<span>{{ currency_symbol }}</span>
							<span>{{ get_price(item) }}</span>
							<span>/ {{ item.unit}}</span>
							<span v-if="site.subscription_type === 'Yearly'" class="text-muted">/ year</span>
							<span v-else class="text-muted">/ month</span>
						</div>
						<div class="mt-3">
							<div v-for="action in item.actions" :key="action.label">
								<a href=""
									class="text-dark"
				   					@click.prevent="action.handler"
								>{{ action.label }}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="less">
@light-yellow:			#fffce7;
@btn-bg:			#f0f4f7;

.multiselect-list {
	.dropdown-menu {
		width: 100%;
	}

	li {
		padding: 9px 11.8px;
	}

	.selectable-item {
		cursor: pointer;
		display: flex;
		justify-content: space-between;

		.octicon-check {
			display: none;
		}
	}

	.selectable-item.selected {
		background-color: @light-yellow;

		.octicon-check {
			display: inline-block;
		}
	}

	.selectable-item:hover, .selectable-item.highlighted {
		background-color: @btn-bg;
	}

	.selectable-items {
		max-height: 200px;
		overflow: auto;
	}
}

</style>
<script>
export default {
	name: 'Addons',
	props: ['site', 'addons', 'billing_cycle', 'site_users', 'plans', 'selected_plan'],
	computed: {
		items() {
			return ['Users', 'Space', 'Emails'].map(key => {
				let addon = this.addons[key];

				let add_addon = (addon, value) => {
					if (addon.addon_type === 'Users') {
						let fields = [{
							fieldtype: 'Int',
							fieldname: 'users',
							label: value === 'add' ? 'Enter number of users' : 'Enter number of users to remove'
						}];

						let plan_details = this.plans.filter(d => d.name==this.selected_plan);

						if( value === 'remove' && (plan_details && this.site_users.length > plan_details[0].users)) {
							fields.push({
								fieldtype: 'MultiSelectList',
								fieldname: 'users_to_remove',
								label: 'Select users to be disabled',
								options: this.site_users
							})
						}

						let d = new frappe.ui.Dialog({
							title: value === 'add' ? 'Add Users' : 'Remove Users',
							fields: fields,
							primary_action: ({ users, users_to_remove}) => {
								users = users ? users: users_to_remove.length;
								this.$emit('add-addon', {
									addon_type: 'Users',
									value: value === 'add' ? users : -users,
									users_to_remove: users_to_remove ? users_to_remove : []
								});
								d.hide();
							}
						});

						d.show();
					} else {
						this.$emit('add-addon', {
							addon_type: addon.addon_type,
							value
						});
					}
				}
				if (key === 'Users') {
					addon.current = this.site.users;

					if (addon.addon_value == 1){
						addon.unit = ' user'
					} else {
						addon.unit = addon.addon_value + ' user'
					}

					addon.actions = [
						{ label: '+ Add Users', handler: () => add_addon(addon, 'add') },
						{ label: '- Remove Users', handler: () => add_addon(addon, 'remove') },
					];
				}
				if (key === 'Emails') {
					addon.current = this.site.emails;
					addon.unit = addon.addon_value + ' emails'
					addon.actions = [
						{ label: '+ Add 5000 Emails', handler: () => add_addon(addon, 5000) },
						{ label: '+ Add 10000 Emails', handler: () => add_addon(addon, 10000) },
						{ label: '+ Add 15000 Emails', handler: () => add_addon(addon, 15000) },
					];
				}
				if (key === 'Space') {
					addon.current = this.site.space;
					addon.unit = addon.addon_value + ' GB'
					addon.actions = [
						{ label: '+ Add 5GB space', handler: () => add_addon(addon, 5) },
						{ label: '+ Add 10GB space', handler: () => add_addon(addon, 10) },
						{ label: '+ Add 15GB space', handler: () => add_addon(addon, 15) },
					];
				}

				return addon;
			});
		},
		currency_symbol() {
			return this.site.currency === 'INR' ? '₹' : '$';
		}
	},
	methods:{
		get_price(item){
			let price = this.billing_cycle === 'Yearly' ? item.amount : item.monthly_amount;
			return format_number(price, '#,###');
		}
	}
}
</script>
