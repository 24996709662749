<template>
    <div v-if="is_cancellation_applicable">
        <div class="d-flex" style="position: absolute; right: 1rem; top: 1rem; bottom: 1rem;" @click.prevent="cancel_subscription()">
            <a href="" target="_blank" class="text-muted small" > Cancel Subscription </a>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Cancel',
    props: ['site', 'cart_details', 'email'],
    methods: {
        cancel_subscription() {
            this.show_reasons_dialog()
        },
        show_reasons_dialog() {
            window.location.href = "../customer-exit-survey?new=1"+"&account_name="+this.site.site_name+'&customer_email='+this.email;
        }
    },
    computed: {
        is_cancellation_applicable() {
            return (!this.cart_details.cart || this.cart_details.cart && !this.cart_details.cart.length) && (Boolean(this.site.subscription_id) && this.site.subscription_status=='Paid');
        }
    }
}
</script>