<template>
	<div class="py-5" style='min-height:650px;'>
		<h4 class="mb-3" style="line-height: 1.5">Manage subscription for <mark>{{ site_name }}</mark></h4>

		<div v-if="!site">
			<CartSkeleton v-for="index in 1" :key="index"/>
		</div>
		<div v-else>
			<div class="alert alert-secondary" role="alert">
				<div>
					<span v-if="site.subscription_status == 'Trial'">
						Your trial expires on <b> {{ formatted_expiry }} </b>
					</span>
					<span v-if="site.subscription_status == 'Paid'">
						Your current subscription expires on <b> {{ formatted_expiry }} </b>
					</span>
				</div>
				<div>
					Number of users in current subscription:  <b> {{ site.users }} </b>
				</div>
			</div>
			<Plans
				v-if="!legacy_plan"
				class="my-5"
				:plans="plans"
				:site="site"
				:cart="cart"
				:legacy_plan="legacy_plan"
				@change-billing-cycle="value => cart.billing_cycle = value"
				@change-plan="value => cart.plan = value"
			/>

			<div v-else class="my-5">
				<h5>Current Plan</h5>
				<div class="row">
					<div class="col-4">
						<PlanCard
							:plan="legacy_plan"
							:is_active="true"
							:currency="site.currency"
							:billing_cycle="'Yearly'"
						/>
					</div>
				</div>
				<div class="mt-3">
					To switch to our new plans, email us on support@erpnext.com
				</div>
			</div>

			<Addons
				v-if="addons"
				class="my-5"
				:site="site"
				:plans="plans"
				:selected_plan="cart.plan"
				:addons="addons"
				:site_users="site_users"
				:billing_cycle="cart.billing_cycle"
				@add-addon="add_addon"
			/>

			<Checkout
				:cart_details="cart_details"
				:cart="cart"
				:site="site"
				:address="address"
				:pending_downgrades="pending_downgrades"
				:erpnext_partner="erpnext_partner"
				:legacy_plan="legacy_plan"
				:plan_wise_discount="plan_wise_discount"
				:email="email"
				@pay="pay"
				@address="show_address_dialog"
				@remove_from_cart="remove_from_cart"
				@process_downgrade="process_downgrade"
				@cancel_downgrade ="cancel_downgrade"
				@switch_billing_cycle="switch_billing_cycle"
			/>

		</div>
	</div>
</template>
<script>
import CartSkeleton from './CartSkeleton.vue';
import BillingCycle from './BillingCycle.vue';
import Plans from './Plans.vue';
import PlanCard from './PlanCard.vue';
import Addons from './Addons.vue';
import Checkout from './Checkout.vue';

export default {
	name: 'Upgrade',
	components: {
		CartSkeleton,
		BillingCycle,
		Plans,
		PlanCard,
		Addons,
		Checkout
	},
	data() {
		return {
			show_plans: true,
			site: null,
			legacy_plan: null,
			plans: [],
			addons: [],
			address: null,
			pending_downgrades: null,
			formatted_expiry: null,
			site_users: null,
			cart: {
				billing_cycle: null,
				plan: null,
				users: null,
				space: null,
				emails: null
			},
			cart_details: [],
			users_to_remove: [],
			plan_wise_discount: {},
		}
	},
	watch: {
		cart: {
			handler(new_value, old_value) {
				this.fetch_cart_details();
			},
			deep: true
		}
	},
	mounted() {
		frappe.call('central.www.upgrade.upgrade.get_site_details', {
			site_name: this.site_name,
			email: this.email,
			erpnext_partner: this.erpnext_partner
		}).then(r => {
			let { site, plans, addons, address, legacy_plan, pending_downgrades, formatted_expiry, site_users, plan_wise_discount } = r.message;
			this.site = site;
			this.plans = plans;
			this.address = address;
			this.legacy_plan = legacy_plan;
			this.pending_downgrades = pending_downgrades;
			this.formatted_expiry = formatted_expiry;
			this.addons = addons;
			this.cart.plan = this.site.base_plan;
			this.cart.billing_cycle = this.site.subscription_type;
			this.site_users = site_users;
			this.plan_wise_discount = plan_wise_discount;
		});
	},
	methods: {
		fetch_cart_details() {
			frappe.call('central.www.upgrade.upgrade.get_cart_details', {
				site_name: this.site_name,
				cart: this.cart,
				erpnext_partner: this.erpnext_partner
			}).then(r => {
				let { addons, cart_details } = r.message;
				this.cart_details = cart_details;
				this.addons = addons;
			});
		},

		pay() {
			let args = {
				site_name: this.site_name,
				cart: this.cart,
				email: this.email,
				erpnext_partner: this.erpnext_partner,
				users_to_remove: this.users_to_remove,
			};
			frappe.call({
				method: 'central.www.upgrade.upgrade.pay',
				args: args,
				freeze: true,
				btn: $(event.target),
				callback: (r) => {
					if(r.message && r.message.redirect_to){
						window.location.href = r.message.redirect_to;
					}
				}
			})
		},

		show_address_dialog() {
			let address = {};

			let d = new frappe.ui.Dialog({
				title: 'Change Address',
				fields: [
					{
						fieldtype: 'Data',
						fieldname: 'name',
						hidden: 1
					},
					{
						fieldtype: 'Data',
						label: 'Company Name',
						fieldname: 'address_title',
						reqd: 1
					},
					{
						fieldtype: 'Data',
						label: 'Tax ID / GSTIN',
						fieldname: 'tax_id'
					},
					{
						fieldtype: 'Data',
						label: 'Address Line 1',
						fieldname: 'address_line1',
						reqd: 1
					},
					{
						fieldtype: 'Select',
						label: 'Country',
						fieldname: 'country',
						options: [],
						change: () => {
							toggle_state();
						}
					},
					{
						fieldtype: 'Data',
						label: 'State',
						fieldname: 'state'
					},
					{
						fieldtype: 'Select',
						label: 'State',
						fieldname: 'state_india'
					},
					{
						fieldtype: 'Data',
						label: 'City',
						fieldname: 'city',
						reqd: 1
					},
					{
						fieldtype: 'Data',
						label: 'Pin Code',
						fieldname: 'pincode'
					},
				],
				primary_action_label: 'Set',
				primary_action: (values) => {
					let address = values;
					address.site = this.site.name;
					d.hide();
					frappe.call({
						method: 'central.www.address.save_billing_address',
						args: { data: address },
						freeze: true,
						freeze_message: __("Saving address"),
						callback: (r) => {
							this.address = r.message;
						}
					});
				}
			});

			frappe.call('central.www.address.get_countries_and_states')
				.then(r => {
					d.set_df_property('country', 'options', r.message.countries);
					d.set_df_property('state_india', 'options', r.message.states);
				});

			if (this.address && this.address.address_dict) {
				this.address.address_dict['state_india'] = this.address.address_dict['state'];
				d.set_values(this.address.address_dict);
				address = this.address.address_dict;
			}

			function toggle_state() {
				let country = d.get_value('country') || address['country'];
				d.get_field('state_india').$wrapper.toggle(country === 'India');
				d.get_field('state').$wrapper.toggle(country !== 'India');
			}

			toggle_state();

			d.show();

		},

		add_addon(addon) {
			if (addon.addon_type === 'Space') {
				this.cart.space = addon.value;
			}
			if (addon.addon_type === 'Emails') {
				this.cart.emails = addon.value;
			}
			if (addon.addon_type === 'Users') {
				this.cart.users = addon.value;
				this.users_to_remove = addon.users_to_remove;
			}
		},

		remove_from_cart(upgrade_type){
			this.cart[upgrade_type.toLowerCase()] = null;
		},

		process_downgrade(){
			frappe.call({
				method: 'central.www.downgrade.process_downgrade',
				args: {
					cart: this.cart_details.cart,
					total: this.cart_details.total,
					site_name: this.site_name,
					email: this.email,
					erpnext_partner: this.erpnext_partner,
					users_to_remove: this.users_to_remove,
				},
				freeze: true,
				freeze_message: "Processing downgrade",
				btn: $(event.target),
				callback: (r) => {
					if (r.message.redirect_to){
						window.location.href = r.message.redirect_to;
					}else{
						frappe.msgprint(r.message.message_to_display);
						setTimeout(function(){
							location.reload();
						}, 5000);
					}
				}
			})
		},

		cancel_downgrade(){
			frappe.call({
				method: 'central.www.downgrade.cancel_downgrade',
				args: {
					site_name: this.site_name,
					email: this.email,
					erpnext_partner: this.erpnext_partner
				},
				freeze: true,
				freeze_message: "Cancelling downgrade",
				btn: $(event.target),
				callback: (r) => {
					if (r.message.redirect_to){
						window.location.href = r.message.redirect_to;
					}else{
						frappe.msgprint(r.message.message_to_display);
						setTimeout(function(){
							location.reload();
						}, 3000);
					}
				}
			})
		},

		switch_billing_cycle(){
			this.cart.billing_cycle = this.cart.billing_cycle == 'Yearly' ? 'Monthly': 'Yearly';
		}
	},
	computed: {
		site_name() {
			let site_name = frappe.utils.get_url_arg('site');
			return frappe.utils.escape_html(site_name);
		},
		email() {
			let email = frappe.utils.get_url_arg('email');
			return frappe.utils.escape_html(email);
		},
		erpnext_partner() {
			let partner = frappe.utils.get_url_arg('erpnext_partner');
			return frappe.utils.escape_html(partner);
		}
	}
}
</script>
